@import "../../../variables";

main.home .open-your-account {
  .container {
    padding-top: 50px;
    margin-top: 70px;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 95%;
      height: 1px;
      background-color: #000;
      top: 0px;
      right: 0px;
      left: 0px;
      margin: auto;
    }
  }
  h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin: 0px;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    padding: 0px;
    margin-top: 54px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      list-style: none;
      max-width: 30%;
      padding-left: 90px;
      min-height: 104px;
      &::before {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 90px;
        font-weight: 700;
        font-size: 150px;
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
      }
      &:nth-child(1):before {
        content: "1";
      }
      &:nth-child(2):before {
        content: "2";
      }
      &:nth-child(3):before {
        content: "3";
      }
      * {
        padding-left: 10px;
      }
      h3 {
        font-weight: 700;
        font-size: 24px;
        margin: 0px;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin: 0px;
      }
    }
  }
  .bottom {
    margin-top: 50px;
  }
  @media (max-width: #{$mobile-width}) {
    .container {
      padding-top: 22px;
      margin-top: 50px;
    }
    h2 {
      font-weight: 400;
      font-size: 7.179487179487179vw;
      line-height: 9.743589743589744vw;
    }
    ul {
      flex-direction: column;
      margin-top: 34px;
      li {
        max-width: 100%;
        padding-left: 20.51282051282051vw;
        &::before {
          width: 20.51282051282051vw;
          font-size: 25.64102564102564vw;
          justify-content: center;
        }
        &:nth-child(n + 2) {
          margin-top: 50px;
        }
        h3 {
          font-size: 6.153846153846154vw;
          line-height: 8.205128205128205vw;
        }
        p {
          font-size: 6.153846153846154vw;
          line-height: 9.230769230769231vw;
        }
      }
    }
  }
}

@import "../../../variables";

main.home .qualities {
  p {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    max-width: 550px;
  }
  @media (max-width: #{$mobile-width}) {
    p {
      font-size: 7.179487179487179vw;
      line-height: 8.205128205128205vw;
    }
  }
}

@import "./variables";

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
}
body,
#app {
  max-width: 100vw;
}
main {
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  max-width: 100%;
  color: var(--color-text-primary)
}
.container {
  max-width: 100%;
  width: 1200px;
  padding-left: 14px;
  padding-right: 14px;
  margin: auto;
}
.flex {
  display: flex;
  &.column {
    flex-direction: column;
    &-reverse {
      flex-direction: column-reverse;
    }
    &.space-between {
      align-items: space-between;
    }
    &.space-around {
      align-items: space-around;
    }
    &.center {
      align-items: center;
    }
    &.left {
      align-items: flex-start;
    }
    &.right {
      align-items: flex-end;
    }
    &.stretch {
      align-items: stretch;
    }
    &.align-space-between {
      justify-content: space-between;
    }
    &.align-space-around {
      justify-content: space-around;
    }
    &.align-center {
      justify-content: center;
    }
    &.align-left {
      justify-content: flex-start;
    }
    &.align-right {
      justify-content: flex-end;
    }
    &.align-stretch {
      justify-content: stretch;
    }
  }
  &.row {
    flex-direction: row;
    &-reverse {
      flex-direction: row-reverse;
    }
    &.space-between {
      justify-content: space-between;
    }
    &.space-around {
      justify-content: space-around;
    }
    &.center {
      justify-content: center;
    }
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    &.stretch {
      justify-content: stretch;
    }
    &.align-space-between {
      align-items: space-between;
    }
    &.align-space-around {
      align-items: space-around;
    }
    &.align-center {
      align-items: center;
    }
    &.align-left {
      align-items: flex-start;
    }
    &.align-right {
      align-items: flex-end;
    }
    &.align-stretch {
      align-items: stretch;
    }
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
}

@media (max-width: #{$mobile-width}) {
  .desktop {
    display: none;
  }
}
@media (min-width: #{$mobile-width + 1px}) {
  .mobile {
    display: none;
  }
  body {
    overflow-x: hidden;
  }
}

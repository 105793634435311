@import "../../variables";

header {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  border-top: solid 1px rgba(0, 0, 0, 0.25);
  backface-visibility: hidden;
  .container {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .logo {
    display: flex;
    align-items: center;
    max-width: 50%;
    picture,
    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  @media (min-width: 781px) {
    .container {
      padding-left: 42px;
      padding-right: 42px;
    }
  }
  @media (max-width: $mobile-width) {
    .logo {
      picture,
      img {
        max-height: 20px;
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      height: 25px;
      width: 120px;
      font-size: 12px;
      line-height: 12px;
      border-radius: 12px/50%;
    }
  }
  @media (max-width: 310px) {
    .button {
      font-size: 3.870967741935484vw;
      line-height: 3.870967741935484vw;
      height: 8.064516129032258vw;
      width: 38.70967741935484vw;
      border-radius: 3.870967741935484vw/50%;
    }
  }
}

main.home .banner-main {
  position: relative;
  * {
    font-family: var(--font-secondary);
  }
  picture,
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  .container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  .text {
    position: absolute;
    top: 3.489583%;
    right: 0;
    font-weight: 600;
    font-size: 75px;
    line-height: 80px;
    color: #fff;
    span {
      color: var(--color-primary);
    }
  }
  @media (max-width: 1200px) {
    .container {
      right: 14px;
    }
    .text {
      font-size: 6.25vw;
      line-height: 6.67vw;
    }
  }
  @media (max-width: 780px) {
    .container,
    .text {
      position: static;
    }
    .text {
      font-size: 16.41025641025641vw;
      line-height: 17.94871794871795vw;
      color: var(--color-text-primary);
      margin: 30px;
    }
  }
}

.big-text {
  margin-top: 50px;
  &__phrase {
    font-family: var(--font-secondary);
    font-size: 190px;
    font-weight: 700;
    opacity: 0.5;
    transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:nth-child(n + 2) {
      margin-top: 40px;
    }
    &.full-opacity {
      opacity: 1;
    }
    &.zero-opacity {
      opacity: 0.1;
    }
    span {
      color: var(--color-primary);
    }
  }
  @media (max-width: 1200px) {
    width: max-content;
    margin-top: 0px;
    &__phrase {
      font-size: 13vw;
      max-width: 100%;
      &:nth-child(n + 2) {
        margin-top: 8px;
      }
    }
  }
}

$font-primary: "DM Sans", sans-serif;
$font-secondary: "Oswald", sans-serif;
$color-primary: #FBBA00;
$color-text-primary: #3C3C3B;
$mobile-width: 991px;

:root {
  --font-primary: #{$font-primary};
  --font-secondary: #{$font-secondary};
  --color-primary: #{$color-primary};
  --color-text-primary: #{$color-text-primary};
  --mobile-width: #{$mobile-width};
  --desktop-min-width: #{$mobile-width + 1px};
}

@import "../../../variables";

main.home .benefits {
  margin-top: 50px;
  li {
    list-style: none;
  }
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  .text {
    flex: 1;
    flex-shrink: 0;
    flex-basis: 60%;
    ul {
      position: relative;
      border-left: solid 1px #000;
      width: 100%;
      li {
        max-width: 486px;
        * {
          pointer-events: none;
        }
        &.bar {
          position: absolute;
          left: -1px;
          top: 0px;
          width: 6px;
          background-color: var(--color-primary);
          z-index: 1;
          transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .image {
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    .content {
      max-width: 24vw;
      overflow: visible;
    }
    .card {
      display: inline-block;
    }
  }
  @media (max-width: #{$mobile-width}) {
    .text {
      ul {
        border-left: none;
        padding: 0px;
        li {
          &.bar {
            display: none;
          }
          & + li {
            margin-top: 30px;
          }
        }
      }
      h2 {
        font-size: 36px;
        margin-top: 30px;
        margin-bottom: 0px;
      }
      p {
        margin-top: 10px;
        margin-bottom: 0px;
      }
    }
  }
}

@import "../../../variables";

main.home .security {
  margin-top: 127px;
  span {
    color: var(--color-primary);
  }
  .top {
    .left,
    .right {
      max-width: calc(50% - 38px);
    }
    .left {
      position: relative;
      font-weight: 600;
      font-size: 96px;
      line-height: 110px;
      width: 511px;
      &,
      * {
        font-family: var(--font-secondary);
      }
      p {
        position: sticky;
        top: calc(50% - 0.8em);
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .right {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        margin: 0px;
        &:nth-child(n + 2) {
          margin-top: 50px;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-top: 0px;
      }
    }
  }
  .bottom {
    font-weight: 600;
    font-size: 130px;
    line-height: 134px;
    margin-top: 70px;
    &,
    * {
      font-family: var(--font-secondary);
    }
    p {
      width: 822px;
      max-width: 100%;
      text-align: center;
      margin: 0px;
    }
  }
  @media (max-width: #{$mobile-width}) {
    margin-top: 8px;
    .top {
      flex-direction: column;
      .left,
      .right {
        max-width: 100%;
      }
      .left {
        p {
          position: static;
          font-size: 22.05128205128vw;
          line-height: 25.64102564102564vw;
          width: 100%;
        }
      }
      .right {
        margin-top: 25px;
        h2 {
          font-weight: 500;
          font-size: 7.692307692307692vw;
          line-height: 7.179487179487179vw;
          &:nth-child(n + 2) {
            margin-top: 24px;
          }
        }
        p {
          font-weight: 400;
          font-size: 4.102564102564103vw;
          line-height: 5.641025641025641vw;
          margin-top: 16px;
        }
      }
    }
    .bottom {
      margin-top: 47px;
      font-size: 12.82051282051282vw;
      line-height: 13.84615384615385vw;
    }
  }
}

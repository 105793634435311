@import "../../../variables";

main.home .caroussel2 {
  margin-top: 50px;
  max-width: 100vw;
  overflow: hidden;
  background: var(--color-primary);
  padding-top: 46px;
  padding-bottom: 46px;
  .container {
    margin-top: 0px;
  }
  .text {
    max-width: 45.833%;
    p {
      color: var(--color-text-primary);
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      width: 246px;
      max-width: 100%;
      &:nth-child(n + 2) {
        margin-top: 30px;
      }
      &:nth-child(3) {
        line-height: 24px;
        width: 330px;
      }
    }
    strong {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #000;
    }
  }

  .controls {
    margin-top: 65px;
    width: 100%;
    .swiper-prev,
    .swiper-next {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
    .swiper-pagination {
      &-custom {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
      }
      &-bullet {
        width: 14px;
        height: 14px;
        background: #000;
        opacity: 1;
        display: block;
        &:nth-child(n + 2) {
          margin-left: 40px;
        }
        &-active {
          background: #fff;
        }
      }
    }
  }
  @media (min-width: #{$mobile-width + 1px}) {
  }
  @media (max-width: #{$mobile-width}) {
    .text {
      max-width: 100%;
      justify-content: center;
      p,
      p:nth-child(3) {
        width: 100%;
        text-align: center;
      }
    }
    .content {
      flex-direction: column-reverse;
    }
    .controls {
      margin-top: 14px;
      .swiper-next,
      .swiper-prev {
        display: none;
      }
    }
  }
}

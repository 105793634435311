@import "../../../variables";

main.home .facilities {
  margin-top: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #f1f1f1;
  .container {
    gap: 14px;
  }
  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    & + p {
      margin-top: 20px;
    }
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    max-width: 357px;
  }
  picture,
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  .item {
    margin-top: 20px;
  }
  @media (max-width: #{$mobile-width}) {
    padding-top: 18px;
    padding-bottom: 18px;
    .container {
      flex-direction: column-reverse;
    }
    h2 {
      font-weight: 500;
      font-size: 7.692307692307692vw;
      line-height: 10.25641025641026vw;
    }
    p {
      font-size: 5.128205128205128vw;
      line-height: 7.692307692307692vw;
    }
    .text,
    .image {
      max-width: 100%;
      width: max-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

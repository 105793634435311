.button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px 22px;
  border-radius: 20px/50%;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  @media (max-width: 378px) {
    font-size: 4vw;
    padding: 1.3vw 4vw;
    border-radius: 4vw/50%;
  }
}

@import "../../../variables";

main.home .caroussel {
  margin-top: 50px;
  overflow: hidden;

  &__texts {
    h2 {
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 490px;
      max-width: 100%;
      text-align: center;
    }
  }
  &__images {
    user-select: none;
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .swiper-wrapper {
    height: max-content;
  }
  .swiper-pagination {
    position: static;
    margin-top: 70px;
    &-bullet {
      width: 14px;
      height: 14px;
      background: #a9a8a8;
      opacity: 1;
      &:nth-child(n + 2) {
        margin-left: 40px;
      }
      &-active {
        background: var(--color-primary);
      }
    }
  }
  @media (min-width: #{$mobile-width + 1px}) {
    &__texts {
      .swiper-slide {
        transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &-prev,
        &-next {
          opacity: 0;
        }
        &-active {
          width: 100% !important;
          margin-left: -33.333%;
        }
      }
    }
  }
  @media (max-width: #{$mobile-width}) {
    .swiper-slide {
      text-align: center;
    }
    .swiper-pagination {
      margin-top: 14px;
    }
    &__texts {
      h2 {
        font-size: 36px;
        line-height: 40px;
        margin-top: 10px;
        margin-bottom: 0px;
      }
      p {
        margin-top: 10px;
        margin-bottom: 0px;
      }
    }
    .button {
      margin-top: 14px;
    }
  }
}
